import Vue from 'vue';
import { Options } from '@/modules/filter/filter.types';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FilterItem from './FilterItem.vue';

@Component({ components: { FilterItem } })
export default class BaseFilter extends Vue {
  @Prop({ required: true, type: String })
  label!: string;

  @Prop({ required: true })
  filterKey!: string | string[];

  @Prop({ required: true })
  value!: string | string[];

  @Prop({ type: Object as () => Options })
  filterOptions!: Options;

  @Prop({ type: Object })
  props!: object;

  @Prop({ type: Boolean })
  disabled!: boolean;

  notifyChange(newValue: string | string[] | boolean | undefined) {
    this.$emit('filter:change', { key: this.filterKey, value: newValue });
  }
}
